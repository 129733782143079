import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_L } from './constants'
import { IconPropsT } from './types'

const DocumentsL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        d="M18.4142 3 26 10.5858V27c0 1.0544-.8159 1.9182-1.8507 1.9945L24 29H8c-1.05436 0-1.91817-.8159-1.99451-1.8507L6 27V5c0-1.05436.81588-1.91817 1.85074-1.99451L8 3h10.4142ZM16 5H8v22h16V14c0-.5523-.4477-1-1-1h-6c-.5523 0-1-.4477-1-1V5Zm2 .416L23.586 11H18V5.416Z"
      />
    </Svg>
  )
}

export default DocumentsL
