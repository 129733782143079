import React, { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import useFilterContext from 'components/Filter/context'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useStateBackLink from 'hooks/useStateBackLink'
import { Trans } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { absencePeriods, absencePeriodsTranslationMap } from 'shared/constants'
import { uiDateFormat } from 'utils/constants'
import { getFormattedFromISOString } from 'utils/date'
import { getShortName } from 'utils/person'
import { absenceRequestStatus } from '../AbsenceRequest/constants'
import { taskStates } from '../constants'
import classes from '../styles'
import { AbsenceRequestTaskT, TaskCardDataT } from '../types'

type TranslationObject = {
  key: string
  variables: {
    from?: string
    to?: string
    absencelength?: string
    frontlinerComment?: string
    managerComment?: string
    frontlinerNameShort?: string
    managerNameShort?: string
  }
}

// this can be merged into only one condition, but maybe too long
const checkVariables = (value: TranslationObject) => {
  if (
    value.key === 'tasks.new.absence.comment_frontliner' &&
    (!value.variables.frontlinerComment || value.variables.frontlinerComment === '')
  ) {
    return false
  }
  if (
    value.key === 'tasks.new.absence.comment_manager' &&
    (!value.variables.managerComment || value.variables.managerComment === '')
  ) {
    return false
  }
  return true
}

const getOfficeHeadline = (status: string): string => {
  switch (status) {
    case absenceRequestStatus.reviewOfficeSeen:
    case absenceRequestStatus.reviewOfficeUnseen:
      return 'tasks.new.absence.manager.open.headline'
    case absenceRequestStatus.deletionRequested:
      return 'tasks.new.absence.manager.open.headline_deletion_requested'
    case absenceRequestStatus.approvedOffice:
      return 'tasks.new.absence.manager.closed.headline_accepted'
    case absenceRequestStatus.rejectedOffice:
      return 'tasks.new.absence.manager.closed.headline_declined'
    case absenceRequestStatus.acceptedDeletionRequest:
      return 'tasks.new.absence.manager.closed.headline_deletion_accepted'
    case absenceRequestStatus.rejectedDeletionRequest:
      return 'tasks.new.absence.manager.closed.headline_deletion_rejected'
    default:
      return ''
  }
}

const getFrontlinerHeadline = (status: string): string => {
  switch (status) {
    case absenceRequestStatus.reviewOfficeSeen:
    case absenceRequestStatus.reviewOfficeUnseen:
      return 'tasks.new.absence.frontliner.pending.headline'
    case absenceRequestStatus.deletionRequested:
      return 'tasks.new.absence.frontliner.pending.headline_deletion_requested'
    case absenceRequestStatus.approvedOffice:
      return 'tasks.new.absence.frontliner.closed.headline_accepted'
    case absenceRequestStatus.rejectedOffice:
      return 'tasks.new.absence.frontliner.closed.headline_declined'
    case absenceRequestStatus.acceptedDeletionRequest:
      return 'tasks.new.absence.frontliner.closed.headline_deletion_accepted'
    case absenceRequestStatus.rejectedDeletionRequest:
      return 'tasks.new.absence.frontliner.closed.headline_deletion_rejected'
    default:
      return ''
  }
}

const AbsenceRequestBody: React.FC<TaskCardDataT<AbsenceRequestTaskT>> = ({ office, task, onClickDetails }) => {
  const { t } = useFavurTranslation()
  const { setBackLink } = useStateBackLink()
  const { storeState } = useFilterContext()
  const history = useHistory()
  const taskState = task.statusFe

  const dateTo = getFormattedFromISOString(task.taskData.dateTo, uiDateFormat, undefined)
  const dateFrom = getFormattedFromISOString(task.taskData.dateFrom, uiDateFormat, undefined)
  const multiday = task.taskData?.dates.length > 1 || ''
  const period = task.taskData?.dates[0].period
  const absenceLength = (() => {
    if (multiday) return ''
    const translatedPeriod = absencePeriodsTranslationMap[period]
    return t(absencePeriods[translatedPeriod])
  })()

  const titleTranslation: TranslationObject = useMemo(() => {
    const key = office ? getOfficeHeadline(task.status) : getFrontlinerHeadline(task.status)
    const variables = (() => {
      if (office) {
        return { frontlinerNameShort: task.person ? getShortName(task.person) : '-' }
      }
      if (taskState === taskStates.closed) {
        return { managerNameShort: task.personTriggeredBy ? getShortName(task.personTriggeredBy) : '-' }
      }
      return {}
    })()
    return { key, variables }
  }, [office, task.person, task.personTriggeredBy, task.status, taskState])

  const contentTranslation: TranslationObject[] = useMemo(() => {
    if ((taskState === taskStates.todo && office) || (taskState === taskStates.pending && !office)) {
      return [
        {
          key: multiday ? 'tasks.new.absence.dates_multiday' : 'tasks.new.absence_singleday',
          variables: { from: dateFrom, ...(multiday ? { to: dateTo } : { absencelength: absenceLength }) },
        },
        {
          key: 'tasks.new.absence.comment_frontliner',
          variables: { frontlinerComment: task.comment },
        },
      ]
    }
    if (taskState === taskStates.closed) {
      return [
        {
          key: multiday ? 'tasks.new.absence.dates_multiday' : 'tasks.new.absence_singleday',
          variables: { from: dateFrom, ...(multiday ? { to: dateTo } : { absencelength: absenceLength }) },
        },
        {
          key: 'tasks.new.absence.comment_frontliner',
          variables: { frontlinerComment: task.comment },
        },
        {
          key: 'tasks.new.absence.comment_manager',
          variables: { managerComment: task.taskData.reviewerComment },
        },
      ]
    }
    return []
  }, [absenceLength, dateFrom, dateTo, multiday, office, task.comment, task.taskData.reviewerComment, taskState])

  const buttons = useMemo(() => {
    return (
      <Typography
        sx={classes.callToAction}
        variant="button"
        component="button"
        onClick={() => {
          if (onClickDetails) onClickDetails()
          else {
            setBackLink(routes.tasks)
            storeState()
            history.push(`${routes.absenceRequestDetail}/${task.favurUuid}`)
          }
        }}
      >
        {office && taskState === taskStates.todo
          ? t(`tasks.${task.taskType}.proceed`)
          : t(`tasks.${task.taskType}.details`)}
      </Typography>
    )
  }, [office, taskState, t, task.taskType, task.favurUuid, onClickDetails, setBackLink, storeState, history])

  return (
    <Box
      sx={classes.taskCardBody}
      data-testid={`${taskState}-${office ? 'office' : 'frontliner'}-absence-request-body`}
    >
      <Typography sx={classes.taskCardBodyTitle} variant="subtitle2">
        {t(titleTranslation.key, titleTranslation.variables)}
      </Typography>
      <Box sx={classes.taskCardContentWrapper}>
        {contentTranslation.map(
          (value) =>
            checkVariables(value) && (
              <Typography sx={classes.taskCardBodyContent} variant="body2" key={`${value.key}_wrapper`}>
                <Trans
                  i18nKey={value.key}
                  components={{ b: <b /> }}
                  values={value.variables}
                  key={value.key}
                  shouldUnescape
                />
              </Typography>
            ),
        )}
      </Box>
      <Box sx={[classes.callToActionContainer, !buttons && classes.noButton]}>{buttons}</Box>
    </Box>
  )
}

export default AbsenceRequestBody
