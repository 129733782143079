import React, { useCallback } from 'react'
import { Box, Typography } from '@mui/material'
import TextInputDrawer from 'components/TextInputDrawer'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { EventsOfTask } from 'pages/Tasks/types'
import usePerformEventToTask from '../ActionButtons/usePerformEventToTask'
import { sendCommentBodyClasses } from './styles'

interface ISendCommentProps {
  isOpen: boolean
  onCancel: () => void
  taskUuid: string
  eventOnSuccess: EventsOfTask
  eventCompleted?: () => void
  required?: boolean
}

const MAX_LENGTH_COMMENT = 2000

const SendComment: React.FC<ISendCommentProps> = ({
  isOpen,
  onCancel,
  eventOnSuccess,
  required = false,
  eventCompleted,
  taskUuid,
}) => {
  const { t } = useFavurTranslation()

  const isManager = eventOnSuccess === EventsOfTask.shareUserDataSendCommentToFrontline

  const { loading, sendCommentToManager, sendCommentToFrontline } = usePerformEventToTask(taskUuid, eventCompleted)

  const onSendComment = useCallback(
    (comment: string) => {
      switch (eventOnSuccess) {
        case EventsOfTask.shareUserDataSendCommentToFrontline:
          sendCommentToFrontline(comment)
          break
        case EventsOfTask.shareUserDataSendCommentToManager:
          sendCommentToManager(comment)
          break
        default:
          return
      }
    },
    [eventOnSuccess, sendCommentToFrontline, sendCommentToManager],
  )

  return (
    <TextInputDrawer
      isOpen={isOpen}
      onCancel={onCancel}
      onSuccess={onSendComment}
      successButtonText={t('shareUserData.sendComment.successButton')}
      textFieldMaxLength={MAX_LENGTH_COMMENT}
      textFieldPlaceHolder={t('shareUserData.sendComment.inputPlaceholder')}
      successButtonDisabled={loading}
      required={required}
      testIdName="send_comment"
    >
      <Box sx={sendCommentBodyClasses.body}>
        <Typography variant="body2" sx={sendCommentBodyClasses.textBody}>
          {t(isManager ? 'shareUserData.sendComment.bodyManager' : 'shareUserData.sendComment.bodyFrontline')}
        </Typography>
      </Box>
    </TextInputDrawer>
  )
}

export default SendComment
