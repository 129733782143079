import React from 'react'
import { SxProps, Theme } from '@mui/material'
import ActionCardButtons from 'components/Buttons/ActionCardButtons'
import { taskStates } from 'pages/Tasks/constants'
import { AbsenceRequestTaskT, EventsOfTask, TaskT } from 'pages/Tasks/types'
import { absenceRequestStatus } from '../../constants'
import Accept from './Accept'
import AddComment from './AddComment'
import CheckEmployeeSaldo from './CheckEmployeeSaldo'
import ConfirmDeletion from './ConfirmDeletion'
import DeleteWorkflow from './DeleteWorkflow'
import Plus from './Plus'
import Reject from './Reject'
import RejectDeletion from './RejectDeletion'
import RequestDeletion from './RequestDeletion'
import SendReminder from './SendReminder'
import ShowAbsencePlan from './ShowAbsencePlan'

interface IActionButtonsProps {
  task: TaskT<AbsenceRequestTaskT>
  isOffice: boolean
  onAction: () => void
  fullScreen?: boolean
  drawerSx?: SxProps<Theme>
  onDelete?: () => void
  hasOverflow?: boolean
  isAtBottom?: boolean
  showAbsencePlanButton?: boolean
}

const getButtonsFromStateFrontline = ({
  task,
  onAction,
  fullScreen,
  drawerSx,
  onDelete,
  hasOverflow,
  isAtBottom,
  showAbsencePlanButton,
}: IActionButtonsProps) => {
  const { favurUuid: taskUuid } = task
  const taskHasTimeline = Boolean(task.timeline?.length)
  if (task.statusFe === taskStates.pending) {
    return (
      <ActionCardButtons fullScreen={fullScreen} hasOverflow={hasOverflow} isAtBottom={isAtBottom}>
        <Plus drawerSx={drawerSx}>
          {taskHasTimeline && (
            <>
              <SendReminder taskUuid={taskUuid} onAction={onAction} />
              {showAbsencePlanButton && <ShowAbsencePlan task={task} />}
              <AddComment
                taskUuid={taskUuid}
                event={EventsOfTask.absenceRequestSendCommentToManager}
                onAction={onAction}
                drawerSx={drawerSx}
              />
            </>
          )}
          {task.status !== absenceRequestStatus.deletionRequested && <DeleteWorkflow task={task} onAction={onDelete} />}
        </Plus>
      </ActionCardButtons>
    )
  }
  if (task.status === absenceRequestStatus.approvedOffice) {
    return (
      <ActionCardButtons fullScreen={fullScreen} hasOverflow={hasOverflow} isAtBottom={isAtBottom}>
        <RequestDeletion task={task} onAction={onAction} drawerSx={drawerSx} />
      </ActionCardButtons>
    )
  }
  return <></>
}

const getButtonsFromStateOffice = ({
  task,
  onAction,
  fullScreen,
  drawerSx,
  hasOverflow,
  isAtBottom,
  showAbsencePlanButton,
}: IActionButtonsProps) => {
  const { favurUuid: taskUuid } = task
  const taskHasTimeline = Boolean(task.timeline?.length)
  if (task.status === absenceRequestStatus.deletionRequested) {
    return (
      <ActionCardButtons fullScreen={fullScreen} hasOverflow={hasOverflow} isAtBottom={isAtBottom}>
        <ConfirmDeletion task={task} onAction={onAction} drawerSx={drawerSx} />
        <Plus drawerSx={drawerSx}>
          <RejectDeletion task={task} onAction={onAction} drawerSx={drawerSx} />
          {showAbsencePlanButton && <ShowAbsencePlan task={task} />}
          <AddComment
            taskUuid={taskUuid}
            event={EventsOfTask.absenceRequestSendCommentToFrontline}
            onAction={onAction}
            drawerSx={drawerSx}
          />
        </Plus>
      </ActionCardButtons>
    )
  }

  if (task.statusFe === taskStates.pending) {
    return (
      <ActionCardButtons fullScreen={fullScreen} hasOverflow={hasOverflow} isAtBottom={isAtBottom}>
        <Accept />
        <Plus drawerSx={drawerSx}>
          <Reject task={task} onAction={onAction} drawerSx={drawerSx} />
          {showAbsencePlanButton && <ShowAbsencePlan task={task} />}
          <CheckEmployeeSaldo task={task} drawerSx={drawerSx} />
          {taskHasTimeline && (
            <AddComment
              taskUuid={taskUuid}
              event={EventsOfTask.absenceRequestSendCommentToFrontline}
              onAction={onAction}
              drawerSx={drawerSx}
            />
          )}
        </Plus>
      </ActionCardButtons>
    )
  }

  return <></>
}

const getButtonsFromTaskAndView = (props: IActionButtonsProps) => {
  const { isOffice, task } = props
  if (isOffice) {
    if (task.statusFe === taskStates.closed) {
      return <></>
    }
    return getButtonsFromStateOffice(props)
  }

  return getButtonsFromStateFrontline(props)
}

const ActionButtons: React.FC<IActionButtonsProps> = (props) => {
  return <>{getButtonsFromTaskAndView(props)}</>
}

export default ActionButtons
