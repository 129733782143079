import React, { useMemo } from 'react'
import { Box, Card, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { ArrowRightS, EventCircleM } from 'icons'
import { Link } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { CmsContentT } from 'types'
import { getMetadata } from '../../helper'
import { styles } from './styles'
import { formatDateRange, formatTitle } from './utils'

type EventCardProps = {
  content: CmsContentT
  date?: string
}

const EventCard: React.FC<EventCardProps> = ({ content, date }) => {
  const { language, t, locale } = useFavurTranslation()
  const metadata = useMemo(() => getMetadata(content, language), [content, language])
  const url = `${routes.cmsContentView}/${content.uuid}${date ? `?dateref=${date}` : ''}`

  if (!metadata) return null

  return (
    <Box component={Link} to={url} sx={styles.contentCardLink}>
      <Card variant="outlined" sx={styles.contentCard}>
        <Box sx={styles.contentCardText}>
          <Box sx={styles.iconText}>
            <EventCircleM />
            <Box sx={styles.texts}>
              <Typography variant="caption" sx={styles.dateRangeText}>
                {formatDateRange({ ...content, locale, t })}
              </Typography>
              <Typography variant="subtitle1" sx={styles.titleText}>
                {formatTitle(metadata.title ?? '')}
              </Typography>
            </Box>
          </Box>
          <Box sx={styles.arrow}>
            <ArrowRightS />
          </Box>
        </Box>
      </Card>
    </Box>
  )
}

export default EventCard
