import React, { useCallback, useMemo } from 'react'
import { Box } from '@mui/material'
import Card from 'components/Basics/Card'
import { ShiftUpdateStatus } from 'components/ShiftsList/types'
import { AbsenceRequestTaskT, ShiftUpdateTaskT, TaskT } from 'pages/Tasks/types'
import { AvatarDataT, CmsContentT, PlannedShiftT, ShiftsByTenantCCT, TenantT } from 'types'
import { TeamplanPersonsT } from 'types/teamplan'
import { groupShiftsByShortDescription } from 'utils/shifts'
import AbsenceRequestData from './AbsenceRequestData'
import ShiftData from './ShiftData'
import TeamsSection from './TeamsSection'
import EventNotification from './components/EventNotification'
import { classes } from './styles'

const getAreThereShifts = (
  groupedShifts: {
    costCenterName: string
    costCenterUuid: string
    shifts: PlannedShiftT[][]
  }[],
): boolean => {
  if (!groupedShifts || groupedShifts.length === 0) {
    return false
  }

  const numShifts = groupedShifts.reduce((acc, gs) => acc + gs.shifts.length, 0)
  return numShifts > 0
}

const getShowTopSeparator = ({
  displayEvents,
  areThereShifts,
  absenceRequests,
}: {
  displayEvents: boolean
  areThereShifts: boolean
  absenceRequests: TaskT<AbsenceRequestTaskT>[]
}) => (areThereShifts && absenceRequests.length > 0) || displayEvents

interface IShiftCardProps {
  events?: CmsContentT[]
  shiftData: ShiftsByTenantCCT[]
  tenant: TenantT
  absenceRequests: TaskT<AbsenceRequestTaskT>[]
  shiftUpdates?: TaskT<ShiftUpdateTaskT>[]
  date?: string
  avatarData?: AvatarDataT
  effectiveShifts?: ShiftsByTenantCCT[]
  teamplan?: 'loading' | TeamplanPersonsT[] | undefined
  effectiveShiftsError?: boolean
}

const ShiftCard: React.FC<IShiftCardProps> = ({
  events = [],
  shiftData,
  tenant,
  absenceRequests,
  date,
  avatarData,
  effectiveShifts,
  teamplan,
  effectiveShiftsError,
  shiftUpdates = [],
}) => {
  const groupedShifts = useMemo(
    () =>
      shiftData.map((costcenter) => ({
        costCenterName: costcenter.costCenterName,
        costCenterUuid: costcenter.costCenterUuid,
        shifts: groupShiftsByShortDescription(costcenter.shifts as PlannedShiftT[]),
      })),
    [shiftData],
  )
  const areThereShifts = getAreThereShifts(groupedShifts)
  const getUpdateTask = useCallback(
    (shiftGroup: PlannedShiftT[]) => {
      const taskIds = shiftGroup.map((shift) => `${shift.updateTaskId}`)
      return shiftUpdates.find((task) => task.id && taskIds.includes(`${task.id}`))
    },
    [shiftUpdates],
  )

  const { hasAcknowledgedShiftUpdate } = useMemo(() => {
    const allShifts = shiftData.flatMap((costcenter) => costcenter.shifts as PlannedShiftT[])
    const task = getUpdateTask(allShifts)
    const acknowledgedShiftUpdate = task === undefined || task.status === ShiftUpdateStatus.reviewFrontlineSeen

    return { hasAcknowledgedShiftUpdate: acknowledgedShiftUpdate }
  }, [getUpdateTask, shiftData])

  const displayEvents = events.length > 0

  return (
    <Box sx={classes.card}>
      <Card data-testid={`shift-card__container--${date}`}>
        <ShiftData
          avatarData={avatarData}
          date={date}
          effectiveShifts={effectiveShifts}
          effectiveShiftsError={effectiveShiftsError}
          shiftData={shiftData}
          shiftUpdates={shiftUpdates}
          tenant={tenant}
        />
        <AbsenceRequestData absenceRequests={absenceRequests} date={date} />
        {displayEvents && <EventNotification events={events} date={date} />}
        {hasAcknowledgedShiftUpdate && (
          <TeamsSection
            key={`teams-section-${date}`}
            teamplan={teamplan}
            showTopSeparator={getShowTopSeparator({ areThereShifts, absenceRequests, displayEvents })}
          />
        )}
      </Card>
    </Box>
  )
}

export default ShiftCard
