import React, { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import Alert from 'components/Alert'
import { DivisionLine } from 'components/Basics'
import Block from 'components/Cms/Block'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { findLast } from 'lodash/fp'
import { CmsContentBlocksT, CmsContentT, History, historyModificationTypes } from 'types'
import { CONTENT_TYPES } from 'types/cms'
import { formatDateRange } from '../EventsList/EventCard/utils'
import { getMetadata } from '../helper'
import HistoryCard from './components/HistoryCard'
import TeamsCard from './components/TeamsCard'
import { styles } from './styles'

const ContentView: React.FC<{ content: CmsContentT }> = ({ content }) => {
  const { t, language, locale } = useFavurTranslation()
  const metadata = useMemo(() => getMetadata(content, language), [content, language])
  const blocks = useMemo(
    () =>
      content.blocks
        ? // eslint-disable-next-line
          [...content.blocks]
            // This filter can be removed if we take the decision to manage the blocks returned by language in the BE
            .filter((block) => block.language === language)
            .sort((a: CmsContentBlocksT, b: CmsContentBlocksT): number => (a.order ?? 0) - (b.order ?? 0))
        : [],
    [content.blocks, language],
  )
  const { history, audiences } = content
  const { restrictAbsenceComment, isAbsenceRestricted } = content
  const displayAlert = isAbsenceRestricted && restrictAbsenceComment

  const createdHistory = useMemo(
    () =>
      history?.find(
        (historyElement: History) => historyElement.modificationType === historyModificationTypes.createContent,
      ),
    [history],
  )
  const updatedHistory = useMemo(
    () =>
      history &&
      findLast(
        (historyElement: History) => historyElement.modificationType === historyModificationTypes.updateContent,
        history,
      ),
    [history],
  )

  if (!metadata || !blocks) return null

  return (
    <Box sx={styles.contentBlock}>
      <Typography sx={styles.title} variant="h1">
        {metadata?.title}
      </Typography>
      {content.contentType === CONTENT_TYPES.event && (
        <Typography sx={styles.date} variant="body1">
          {formatDateRange({ ...content, locale, t })}
        </Typography>
      )}
      {displayAlert && (
        <Box sx={styles.absenceRequestAlert}>
          <Alert title={t('page.cms.content.absenceBlocker.title')} severity="info" content={restrictAbsenceComment} />
        </Box>
      )}
      {blocks.map((block) => (
        <Box sx={styles.blockWrapper} key={block.order}>
          <Block block={block} />
        </Box>
      ))}
      <Box sx={styles.footer}>
        <DivisionLine />
        {createdHistory ? <HistoryCard history={createdHistory} /> : null}
        {updatedHistory ? <HistoryCard history={updatedHistory} /> : null}
        {audiences ? <TeamsCard audiences={audiences} /> : null}
      </Box>
    </Box>
  )
}

export default ContentView
