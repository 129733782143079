import theme from 'utils/theme'

export const TASKBAR_WIDTH = 467

export const taskBarClasses = {
  parentContainer: {
    height: '100%',
    display: 'flex',
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 10,
  },
  mainContainer: {
    background: theme.palette.common.white,
    display: 'flex',
  },
  barContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  openDrawer: {
    width: `${TASKBAR_WIDTH}px`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shortest,
    }),
  },
  closedDrawer: {
    width: '67px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shortest,
    }),
  },
  openContentContainer: (isSmallScreen: boolean) => ({
    boxShadow: isSmallScreen ? 0 : theme.shadows[1],
    width: '400px',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 'var(--ion-safe-area-bottom)',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  }),
  toggleButtonContainer: {
    cursor: 'pointer',
    position: 'sticky',
    bottom: 8,
    zIndex: 1,
    background: theme.palette.common.white,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 0',
  },
  sideMenu: {
    position: 'absolute',
    width: '320px',
    borderRadius: `${theme.spacing(0.5)} 0 0 0`,
    height: '100%',
  },
  sideMenuContainer: {
    marginTop: 'var(--ion-safe-area-top)',
  },
  filtersContainer: {
    overflowY: 'auto',
    padding: '24px',
  },
  filtersContainerNative: {
    paddingTop: '0px',
  },
  filtersTitleContainer: {
    marginBottom: '20px',
  },
  filtersTitle: {
    color: theme.palette.primary.main,
    lineHeight: '24px',
    fontWeight: 500,
    marginBottom: '16px',
  },
  eventsContainerNative: {
    gap: 0,
  },
  eventsContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    padding: '24px',
    gap: '16px',
  },
  eventsContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  eventsTitle: {
    color: theme.palette.primary.main,
    lineHeight: '24px',
    fontWeight: 500,
    marginBottom: '16px',
  },
  closeIcon: {
    color: 'white',
    zIndex: 9999,
    '&:hover, &:focus, &:hover:before': {
      backgroundColor: 'transparent',
    },
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    alignItems: 'center',
    marginBottom: '16px',
  },
  headerTitle: {
    color: theme.palette.primary.main,
  },
}
