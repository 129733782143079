import React, { useCallback } from 'react'
import NotificationTemplate from 'components/Notification/Templates/Notification'
import { getBadgeGlossaryKey } from 'components/Notification/utils'
import { ROLE_VIEWS } from 'constants/roles'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useHistoryUtils from 'hooks/useHistoryUtils'
import routes from 'services/RoutesProvider/routes'
import { AbsenceRequestCommentForFrontlinerNotificationT, NotificationT } from 'types'

interface IAbsenceRequestCommentFrontlinerProps {
  notification: AbsenceRequestCommentForFrontlinerNotificationT
  dismiss: (notification: NotificationT) => void
}

const AbsenceRequestCommentFrontliner: React.FC<IAbsenceRequestCommentFrontlinerProps> = (props) => {
  const { t } = useFavurTranslation()
  const { pushWithRole } = useHistoryUtils()

  const { notification, dismiss } = props

  const handleDetails = useCallback(() => {
    dismiss(notification)
    pushWithRole(`${routes.absenceRequestDetail}/${notification.data.taskUuid}`, ROLE_VIEWS.frontliner)
  }, [dismiss, notification, pushWithRole])

  const templateProps = {
    ...props,
    title: t('notification.new.absence.frontliner.comment.title', {
      managerNameShort: notification.data.managerNameShort,
    }),
    body: t('notification.new.absence.frontliner.comment.body', {
      commentShort: notification.data.commentShort ?? t(getBadgeGlossaryKey(notification.data.badge)),
    }),
    dismissText: t('component.notification.button.dismiss'),
    details: handleDetails,
    detailsText: t('component.notification.button.viewDetails'),
  }

  return <NotificationTemplate {...templateProps} />
}

export default AbsenceRequestCommentFrontliner
