import React, { useMemo, useState } from 'react'
import Tutorial from 'components/Tutorial'
import IntroPage from 'components/Tutorial/IntroPage'
import StepPage from 'components/Tutorial/StepPage'
import { TutorialStepT } from 'components/Tutorial/types'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useHistory } from 'react-router-dom'
import { useAppStatesContext } from 'services/AppStates'
import routes from 'services/RoutesProvider/routes'

export const resignationTenantNameKey = 'STATE_RESIGNATION_TENANT_NAME'

const Resignation: React.FC = () => {
  const tutorialName = 'resignation'
  const { t } = useFavurTranslation()
  const history = useHistory()
  const [step, setStep] = useState(0)
  const { get, clear } = useAppStatesContext()

  const goToDashboard = () => {
    clear(resignationTenantNameKey)
    history.push(routes.dashboard)
  }

  const tenantName = useMemo(() => get(resignationTenantNameKey), [get])

  const stepPages: TutorialStepT[] = [
    {
      key: 'step1',
      titleText: t(`tutorial.resignation.step1.titleText`),
      mainText: t(`tutorial.resignation.step1.text`),
    },
    {
      key: 'step2',
      titleText: t(`tutorial.resignation.step2.titleText`),
      mainText: t(`tutorial.resignation.step2.text`),
    },
    {
      key: 'step3',
      titleText: t(`tutorial.resignation.step3.titleText`),
      mainText: t(`tutorial.resignation.step3.text`),
    },
    {
      key: 'step4',
      close: () => goToDashboard(),
      goTo: () => goToDashboard(),
      goToTitle: t('tutorial.resignation.step4.goToDashboard'),
      titleText: t(`tutorial.resignation.step4.titleText`),
      mainText: t(`tutorial.resignation.step4.text`),
    },
  ]

  const stepPagesComponents = stepPages.map((stepPage) => (
    <StepPage key={stepPage.key} tutorialName={tutorialName} stepProps={stepPage} />
  ))

  const introPage = (
    <IntroPage
      tutorialName={tutorialName}
      stepName="intro"
      close={goToDashboard}
      titleText={t(tenantName ? 'tutorial.resignation.intro.title' : 'tutorial.resignation.intro.titleNoTenant', {
        tenantName,
      })}
    />
  )

  return (
    <Tutorial
      introPage={introPage}
      stepPages={stepPagesComponents}
      step={step}
      setStep={setStep}
      onSwipeEnd={() => goToDashboard()}
      startButtonTextKey="tutorial.resignation.intro.start"
    />
  )
}

export default Resignation
