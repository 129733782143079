import { useEffect, useState } from 'react'
import { AuthenticationKind } from 'constants/authentication'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import useSessionContext from 'hooks/useSessionContext'
import { useLocation } from 'react-router'
import routes from 'services/RoutesProvider/routes'
import { markAnncouncementSeen, userAnnouncementsQuery } from 'shared/queries'
import { MutationResult, UserAnnouncementT } from 'types'
import { useMutation } from '@apollo/client'

export const useUserAnnouncements = () => {
  const { pathname } = useLocation()
  const { auth } = useSessionContext()

  const [userAnnouncements, setUserAnnouncements] = useState<UserAnnouncementT[]>([])
  const [routeAnnouncement, setRouteAnnouncement] = useState<UserAnnouncementT>()
  const [showAnnouncement, setShowAnnouncement] = useState(false)

  const { refetch } = useJamesApolloQuery<{ userAnnouncements: UserAnnouncementT[] }>(userAnnouncementsQuery, {
    onCompleted: (data) => {
      setUserAnnouncements(data.userAnnouncements)
    },
    skip: auth < AuthenticationKind.AUTHENTICATED,
  })
  const [markAnnouncementSeen] = useMutation<MutationResult>(markAnncouncementSeen)

  useEffect(() => {
    if (userAnnouncements.length > 0) {
      // Refetch for when we close the drawer without calling onClose (when navigating through link)
      if (showAnnouncement) {
        refetch()
        setUserAnnouncements([])
        setRouteAnnouncement(undefined)
        setShowAnnouncement(false)
        return
      }
      const routeAnnouncements = userAnnouncements.filter(({ announcement }) => announcement.route === pathname)
      setRouteAnnouncement(routeAnnouncements[0])
      setShowAnnouncement(routeAnnouncements.length > 0)
    }

    // Needed to ignore unnecessary showAnnouncement updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, userAnnouncements])

  // We refetch the announcement list whenever a user goes back to the dashboard to keep it up to date
  useEffect(() => {
    if (routes.dashboard === pathname) {
      refetch()
    }
  }, [pathname, refetch])

  // We mark announcements as seen whenever we load a new one to show
  useEffect(() => {
    if (routeAnnouncement) {
      markAnnouncementSeen({ variables: { userAnnouncementId: routeAnnouncement.id } })
    }
  }, [markAnnouncementSeen, routeAnnouncement])

  return { routeAnnouncement, showAnnouncement, setShowAnnouncement, refetch }
}
