import React, { useContext, useState } from 'react'
import { Button, Box, SxProps, Theme } from '@mui/material'
import RejectAbsenceRequest from 'components/TaskBadge/AbsenceRequest/RejectAbsenceRequest'
import TextInputDrawer from 'components/TextInputDrawer'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { AbsenceRequestTaskT, TaskT } from 'pages/Tasks/types'
import { ApproveAbsenceRequestResultT } from 'types'
import { useMutation } from '@apollo/client'
import { absenceRequestStatus } from '../../constants'
import { updateAbsenceRequestMutationApollo as rejectMutation } from '../../mutations'
import AbsenceSummary from '../Summary'
import { PlusContext } from './Plus'
import { classes } from './styles'

interface IRejectProps {
  task: TaskT<AbsenceRequestTaskT>
  onAction: () => void
  drawerSx?: SxProps<Theme>
}

const MAX_LENGTH_COMMENT = 100

const Reject: React.FC<IRejectProps> = ({ task, onAction, drawerSx }) => {
  const { t } = useFavurTranslation()

  const { setIsOpenDrawer } = useContext(PlusContext)
  const [openDrawer, setOpenDrawer] = useState(false)

  const [rejectAbsenceRequest, { loading: mutationLoading }] = useMutation<ApproveAbsenceRequestResultT>(rejectMutation)

  const onClick = async (commentValue: string) => {
    const mutationResult = await rejectAbsenceRequest({
      variables: {
        reviewerComment: commentValue,
        status: absenceRequestStatus.rejectedOffice,
        taskUuid: task.favurUuid,
      },
    })

    if (mutationResult.data?.updateAbsenceStatus.success) {
      setIsOpenDrawer(false)
      setOpenDrawer(false)
      onAction()
    }
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => {
          setIsOpenDrawer(false)
          setOpenDrawer(true)
        }}
        data-testid="absence-request_action-reject"
      >
        {t('absenceRequest.buttons.reject')}
      </Button>

      <TextInputDrawer
        isOpen={openDrawer}
        onCancel={() => {
          setOpenDrawer(false)
        }}
        onSuccess={onClick}
        successButtonText={t('absenceRequest.drawer.button.comment')}
        textFieldMaxLength={MAX_LENGTH_COMMENT}
        textFieldPlaceHolder={t('absenceRequest.reject.comment.placeholder')}
        successButtonDisabled={mutationLoading}
        testIdName="reject"
        paperSx={drawerSx}
      >
        <Box sx={classes.commentHeaderContainer}>
          <Box sx={classes.commentBadgeContainer}>
            <RejectAbsenceRequest />
          </Box>
          <AbsenceSummary task={task} withEmployee greyVariant />
        </Box>
      </TextInputDrawer>
    </>
  )
}

export default Reject
