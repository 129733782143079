import React from 'react'
import { Box, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { Audience } from 'types'
import { styles } from '../styles'

type TeamCardProps = {
  audiences: Audience[]
}

const TeamsCard: React.FC<TeamCardProps> = ({ audiences }) => {
  const { t, locale } = useFavurTranslation()

  const getAudienceName = (audience: Audience) => {
    if (!locale) return null
    if (audience.audienceType === 'owner') return t('page.cms.content.target.owner')

    switch (locale.code) {
      case 'en':
      case 'en-GB':
        return ` ${audience.nameEn}`
      case 'de':
        return ` ${audience.nameDe}`
      case 'fr':
        return ` ${audience.nameFr}`
      case 'it':
        return ` ${audience.nameIt}`
      default:
        return null
    }
  }
  return (
    <Box sx={styles.historyContainer}>
      <Typography variant="body1" sx={styles.teams}>
        {`${t('page.cms.content.target')} ${
          locale && audiences.map((audience: Audience) => getAudienceName(audience))
        }.`}
      </Typography>
    </Box>
  )
}

export default TeamsCard
