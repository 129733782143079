import { Locale } from 'date-fns'
import { formatDateRange } from 'pages/Cms/EventsList/EventCard/utils'
import { TFunction } from 'react-i18next'
import { CmsNewContentNotificationT, CmsUpdateContentNotificationT } from 'types'

export const contentTranslationPropsFromNotification = (
  notification: CmsNewContentNotificationT | CmsUpdateContentNotificationT,
  t: TFunction<'translation', undefined>,
  locale: Locale | undefined,
) => ({
  title: notification.data.title,
  ...(notification.data.startDate && notification.data.endDate
    ? {
        date: formatDateRange({
          startDate: notification.data.startDate,
          endDate: notification.data.endDate,
          isWholeDay: notification.data.isWholeDay,
          t,
          locale,
        }),
      }
    : {}),
})
