import React from 'react'
import { tutorialTypes } from 'constants/tutorials'
import { RouteComponentProps } from 'react-router-dom'
import TutorialAbsenceRequestFrontliner from './AbsenceRequestFrontliner'
import TutorialAbsenceRequestManager from './AbsenceRequestManager'
import TutorialAbsenceplanFrontliner from './AbsenceplanFrontliner'
import TutorialAbsenceplanManager from './AbsenceplanManager'
import Resignation from './Resignation'
import TutorialShiftplan from './Shiftplan'
import TutorialWorkTimeControlFrontliner from './WorkTimeControlFrontliner'
import TutorialWorkTimeControlManager from './WorkTimeControlManager'

const Tutorial: React.FC<RouteComponentProps<{ tutorialName: string }>> = ({ match }) => {
  const { tutorialName } = match.params

  switch (tutorialName) {
    case tutorialTypes.shiftPlan:
      return <TutorialShiftplan />
    case tutorialTypes.absecenRequestFrontliner:
      return <TutorialAbsenceRequestFrontliner />
    case tutorialTypes.absenceRequestManager:
      return <TutorialAbsenceRequestManager />
    case tutorialTypes.workTimeControlFronliner:
      return <TutorialWorkTimeControlFrontliner />
    case tutorialTypes.workTimeControlManager:
      return <TutorialWorkTimeControlManager />
    case tutorialTypes.absencePlanManager:
      return <TutorialAbsenceplanManager />
    case tutorialTypes.absencePlanFrontliner:
      return <TutorialAbsenceplanFrontliner />
    case tutorialTypes.resignation:
      return <Resignation />

    default:
      return <></>
  }
}

export default Tutorial
