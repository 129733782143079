import theme from 'utils/theme'

export const styles = {
  blockWrapper: {
    marginBottom: '16px',
  },
  contentBlock: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 12px 0 12px',
    marginTop: '24px',
  },
  container: {
    paddingBottom: 'calc(100px + var(--ion-safe-area-bottom))',
  },
  title: {
    marginBottom: '8px',
  },
  absenceRequestAlert: {
    marginBottom: '24px',
  },
  date: {
    color: theme.palette.grey[600],
    marginBottom: '8px',
  },
  header: {
    backgroundColor: 'white !important',
    // @ts-ignore
    color: `${theme.palette.primary[900]}  !important`,
    zIndex: 10,
  },
  footer: {
    margin: '16px 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  historyContainer: {
    display: 'flex',
    gap: '12px',
  },
  avatar: {
    margin: '4px 0 4px 0',
  },
  historyTextContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  teams: {
    color: theme.palette.grey[600],
  },
}
