import { performEventToTask } from 'pages/Tasks/queries'
import { EventsOfTask } from 'pages/Tasks/types'
import { MutationResult } from 'types'
import { useMutation } from '@apollo/client'

const usePerformEventToTask = (favurUuid: string, onCompleted = () => {}) => {
  const [performEventToTaskFunction, { loading }] = useMutation<{ performEventToTask: MutationResult }>(
    performEventToTask,
    {
      onCompleted,
    },
  )

  const sendCommentToManager = (comment: string) => {
    performEventToTaskFunction({
      variables: {
        favurUuid,
        event: EventsOfTask.shareUserDataSendCommentToManager,
        comment,
      },
    })
  }

  const sendCommentToFrontline = (comment: string) => {
    performEventToTaskFunction({
      variables: {
        favurUuid,
        event: EventsOfTask.shareUserDataSendCommentToFrontline,
        comment,
      },
    })
  }
  return {
    loading,
    sendCommentToManager,
    sendCommentToFrontline,
  }
}

export default usePerformEventToTask
