import { format, isThisYear, isToday } from 'date-fns'
import { groupBy, orderBy } from 'lodash/fp'
import type { CmsContentT } from 'types'
import { EVENT_TIMELINE_FILTERS, EventTimelineT } from 'types/cms'
import { getFormattedFromDate, newDateWithoutTime } from 'utils/date'
import {
  GROUP_MONTH_DATE_FORMAT,
  LABEL_MONTH_DATE_FORMAT,
  LABEL_MONTH_DATE_FORMAT_WITH_YEAR,
  TODAY_LABEL_ASC,
  TODAY_LABEL_DESC,
} from './constants'

export type EventGroupItem = {
  dateLabel: string
  contents: CmsContentT[]
}

export type Interval = {
  start: Date
  end: Date
}

const isWithinInterval = (date: Date, interval: Interval) => {
  const start = format(interval.start, 'yyyy-MM-dd')
  const end = format(interval.end, 'yyyy-MM-dd')
  const current = format(date, 'yyyy-MM-dd')

  return current >= start && current <= end
}

const eventIsToday = (content: CmsContentT) => {
  if (!content.startDate) return false
  if (isToday(content.startDate)) return true
  if (!content.endDate) return false

  const start = newDateWithoutTime(content.startDate)
  const end = newDateWithoutTime(content.endDate)
  const current = newDateWithoutTime()

  return isWithinInterval(current, { start, end })
}

const getMonthDateKey = (content: CmsContentT, eventTimelineFilter?: EventTimelineT) => {
  if (eventIsToday(content)) {
    return eventTimelineFilter === EVENT_TIMELINE_FILTERS.PAST ? TODAY_LABEL_DESC : TODAY_LABEL_ASC
  }

  const startDate = content.startDate as string
  const date = newDateWithoutTime(startDate)
  date.setDate(1)
  return getFormattedFromDate(date, GROUP_MONTH_DATE_FORMAT)
}

const formatDateGroup = (dateStr: string) => {
  if (dateStr === TODAY_LABEL_ASC || dateStr === TODAY_LABEL_DESC) return 'page.cms.event.groupToday'

  const date = newDateWithoutTime(dateStr, GROUP_MONTH_DATE_FORMAT)
  return getFormattedFromDate(date, isThisYear(date) ? LABEL_MONTH_DATE_FORMAT : LABEL_MONTH_DATE_FORMAT_WITH_YEAR)
}

export const groupEvents = (contentList?: CmsContentT[], eventTimelineFilter?: EventTimelineT): EventGroupItem[] => {
  if (!contentList) return []

  const contentsGroupedByMonth = groupBy((content) => getMonthDateKey(content), contentList)

  const keyMonthSorted = orderBy(
    (month) => month,
    eventTimelineFilter === EVENT_TIMELINE_FILTERS.PAST ? 'desc' : 'asc',
    Object.keys(contentsGroupedByMonth),
  )

  return keyMonthSorted.map((keyMonth) => ({
    dateLabel: formatDateGroup(keyMonth),
    contents: contentsGroupedByMonth[keyMonth],
  }))
}
