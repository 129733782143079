export const BLOCK_TYPE = {
  divider: 'divider',
  document: 'document',
  image: 'image',
  text: 'text',
  link: 'link',
  icon: 'icon',
} as const

export const PUBLISHER_URI_PATH = '/publisher'

export const BLOCK_TYPE_PATH = {
  [BLOCK_TYPE.document]: '/document',
  [BLOCK_TYPE.image]: '/image',
} as const
