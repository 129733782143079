import React, { useMemo } from 'react'
import { Box } from '@mui/material'
import LoadMoreButton from 'components/LoadMoreButton'
import { SimpleLoading } from 'components/LoadingIcon'
import { CONTENT_TYPES, EventTimelineT } from 'types/cms'
import useListCmsPaginatedContents from '../../../hooks/useListCmsPaginatedContents'
import { styles } from '../styles'
import EventGroup from './EventGroup'
import NoEvents from './NoEvents'
import { groupEvents } from './utils'

const EventTab: React.FC<{ eventTimelineFilter: EventTimelineT }> = ({ eventTimelineFilter }) => {
  const { list, loading, hasMoreElements, loadMore } = useListCmsPaginatedContents({
    contentType: CONTENT_TYPES.event,
    eventTimelineFilter,
  })

  const groupedEvents = useMemo(() => groupEvents(list, eventTimelineFilter), [list, eventTimelineFilter])

  if (!loading && groupedEvents.length === 0) {
    return <NoEvents />
  }

  return (
    <>
      <Box sx={styles.cardContainer}>
        {groupedEvents.map((group) => (
          <EventGroup key={group.dateLabel} dateLabel={group.dateLabel} contents={group.contents} />
        ))}
      </Box>
      {loading && <SimpleLoading />}
      {!loading && hasMoreElements && <LoadMoreButton onClick={loadMore} />}
    </>
  )
}

export default EventTab
