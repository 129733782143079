import React from 'react'
import { Box, Typography } from '@mui/material'
import Page from 'components/Page'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { RouteComponentProps } from 'react-router-dom'
import PageList from './PageList'

const ContentIndexPage: React.FC<RouteComponentProps> = () => {
  const { t } = useFavurTranslation()

  return (
    <Page
      header={
        <Box>
          <Typography variant="h2">{t('page.cms.content.header')}</Typography>
        </Box>
      }
    >
      <PageList />
    </Page>
  )
}

export default ContentIndexPage
