import React, { useMemo } from 'react'
import { Box, Card, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { ArrowRightS } from 'icons'
import { Link } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { CmsContentT } from 'types'
import { getMetadata } from '../helper'
import { styles } from '../styles'

interface IContentCardProps {
  content: CmsContentT
}

const ContentCard: React.FC<IContentCardProps> = ({ content }) => {
  const { language } = useFavurTranslation()
  const metadata = useMemo(() => getMetadata(content, language), [content, language])

  if (!metadata) return null

  return (
    // In the future we should change the uuid for slug
    <Box component={Link} to={`${routes.cmsContentView}/${content.uuid}`} sx={styles.contentCardLink}>
      <Card variant="outlined" sx={styles.contentCard}>
        <Box sx={styles.contentCardText}>
          <Typography variant="subtitle1">{metadata.title ?? ''}</Typography>
          <Typography variant="caption">{metadata.shortDescription ?? ''}</Typography>
        </Box>
        <ArrowRightS />
      </Card>
    </Box>
  )
}

export default ContentCard
