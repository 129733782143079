import React from 'react'
import { Box, useMediaQuery } from '@mui/material'
import BackwardLink from 'components/BackwardLink'
import { SimpleLoading } from 'components/LoadingIcon'
import Page from 'components/Page'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useGoBackShiftDate from 'hooks/useGoBackShiftDate'
import { RouteComponentProps } from 'react-router-dom'
import { CmsContentT } from 'types'
import { CONTENT_TYPES } from 'types/cms'
import theme from 'utils/theme'
import ContentView from './ContentView'
import { styles } from './styles'
import useGetCmsContent from './useGetCmsContent'

const ContentViewPage: React.FC<RouteComponentProps<{ contentUuid: string }>> = ({ match }) => {
  const { t } = useFavurTranslation()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const {
    params: { contentUuid },
  } = match
  const { content, loading } = useGetCmsContent({ contentUuid: contentUuid })
  const { gotoBackLink } = useGoBackShiftDate({ shouldPushBackLink: false })
  const title = t(
    content?.contentType === CONTENT_TYPES.page ? 'page.cms.content.detail.title' : 'page.cms.event.detail.title',
  )

  if (loading) {
    return <SimpleLoading />
  }

  return (
    <Page
      backgroundColor="white"
      header={<BackwardLink onClickIcon={gotoBackLink} title={title} />}
      hideNativeNavigation
      hideHamburger
      noPadding
      hideWebToolbar
      removeLastMargin
      sxHeader={styles.header}
    >
      <Box sx={isSmallScreen ? styles.container : []}>
        <ContentView content={content as CmsContentT} />
      </Box>
    </Page>
  )
}

export default ContentViewPage
