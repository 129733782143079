import React, { useCallback, useContext, useState } from 'react'
import { Button, SxProps, Theme } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { EventsOfTask } from 'pages/Tasks/types'
import SendComment from '../SendComment'
import { PlusContext } from './Plus'
import { classes } from './styles'

interface IAddCommentProps {
  taskUuid: string
  event: EventsOfTask.absenceRequestSendCommentToFrontline | EventsOfTask.absenceRequestSendCommentToManager
  onAction: () => void
  drawerSx?: SxProps<Theme>
}

const AddComment: React.FC<IAddCommentProps> = ({ taskUuid, event, onAction, drawerSx }) => {
  const { t } = useFavurTranslation()
  const { setIsOpenDrawer: setPlusDrawerOpen } = useContext(PlusContext)

  const [openDrawer, setOpenDrawer] = useState(false)
  const onCompleted = useCallback(() => {
    setPlusDrawerOpen(false)
    setOpenDrawer(false)
    onAction()
  }, [onAction, setPlusDrawerOpen])

  return (
    <>
      <Button
        variant="contained"
        sx={classes.regularButton}
        onClick={() => setOpenDrawer(true)}
        data-testid="absence-request_action-send_comment"
      >
        {t('absenceRequest.buttons.addComment')}
      </Button>
      <SendComment
        isOpen={openDrawer}
        onCancel={() => setOpenDrawer(false)}
        taskUuid={taskUuid}
        eventOnSuccess={event}
        eventCompleted={onCompleted}
        required
        drawerSx={drawerSx}
      />
    </>
  )
}

export default AddComment
