export type ContentTypeT = 'page' | 'event'
export type EventTimelineT = 'PAST' | 'UPCOMING'

export const CONTENT_TYPES = {
  page: 'page',
  event: 'event',
} as const

export const EVENT_TIMELINE_FILTERS = {
  PAST: 'PAST',
  UPCOMING: 'UPCOMING',
} as const
