import React, { useCallback, useContext } from 'react'
import { Button } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { errorCodes } from 'pages/Tasks/constants'
import usePerformEventToTask from 'pages/Tasks/hooks/usePerformEventToTask'
import FlashMessagesContext from 'services/FlashMessages/context'
import { getFirstErrorCode } from 'utils/apollo'
import theme from 'utils/theme'
import { PlusContext } from './Plus'

interface ISendReminderProps {
  taskUuid: string
  onAction: () => void
  isOffice?: boolean
}

const SendReminder: React.FC<ISendReminderProps> = ({ taskUuid, onAction, isOffice = false }) => {
  const { t } = useFavurTranslation()
  const { sendReminderToFrontline, sendReminderToManager } = usePerformEventToTask(taskUuid)
  const { setFlashMessage, removeAll } = useContext(FlashMessagesContext)
  const { setIsOpenDrawer } = useContext(PlusContext)

  const sendReminder = isOffice ? sendReminderToFrontline : sendReminderToManager

  const submit = useCallback(() => {
    removeAll()

    sendReminder()
      .then((_result) => {
        setIsOpenDrawer(false)
        onAction()
      })
      .catch((e) => {
        setIsOpenDrawer(false)
        const code = getFirstErrorCode(e)

        if (errorCodes.includes(code)) setFlashMessage(t(code))
        else setFlashMessage(t('common.error.be.default'))
      })
  }, [onAction, removeAll, sendReminder, setFlashMessage, setIsOpenDrawer, t])

  return (
    <>
      <Button
        variant="contained"
        sx={{
          color: theme.palette.primary.main,
          // @ts-ignore
          backgroundColor: theme.palette.primary[50],
          '&:hover': {
            // @ts-ignore
            backgroundColor: theme.palette.primary[50],
            // @ts-ignore
            borderColor: theme.palette.primary[50],
          },
        }}
        onClick={submit}
        data-testid="monthly-sheet-detail_send_reminder"
      >
        {t('workTimeControl.buttons.sendReminder')}
      </Button>
    </>
  )
}

export default SendReminder
