import React from 'react'
import { Box } from '@mui/material'
import ComponentBadge from 'components/ComponentBadge'
import TeaserToAvatar from 'components/TeaserToAvatar'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { EventM, ManagerTasksM, TaskCenterM } from 'icons'
import { useTaskBarStateDispatchContext } from 'pages/AbsencePlanner/contexts/TaskBarStateContext'
import { useSelectedStatesActionsContext } from 'pages/AbsencePlanner/contexts/selectedStatesContext'
import palette from 'utils/theme/palette'
import FilterContainer from '../Filters/FilterContainer'
import { taskBarStates } from '../types'
import Button from './Button'
import PlanAbsenceButton from './PlanAbsenceButton'
import { classes } from './styles'

interface INativeCTAProps {
  taskCountTotal: number
  isAbsenceManager: boolean
}

const NativeCTA: React.FC<INativeCTAProps> = ({ taskCountTotal, isAbsenceManager }) => {
  const { t } = useFavurTranslation()
  const setTaskBarState = useTaskBarStateDispatchContext()
  const { deselectPerson, setSelectedTaskUuid } = useSelectedStatesActionsContext()

  return (
    <Box sx={classes.nativeEntryPointsContainer}>
      <TeaserToAvatar isAbsencePlanner />
      <FilterContainer title={t('absencePlanner.taskbar.menu.views')}>
        {isAbsenceManager && (
          <Button
            icon={
              <ComponentBadge badgeContent={taskCountTotal} max={999}>
                <ManagerTasksM fill={palette.primary[700]} />
              </ComponentBadge>
            }
            label={t('absencePlanner.taskbar.toolTip.managerTasks')}
            onClick={() => {
              deselectPerson()
              setTaskBarState(taskBarStates.managerTasks)
            }}
          ></Button>
        )}
        <Button
          icon={<TaskCenterM fill={palette.primary[700]} />}
          label={t('absencePlanner.taskbar.toolTip.pendingTasks')}
          onClick={() => {
            setSelectedTaskUuid(null)
            setTaskBarState(taskBarStates.userTasks)
          }}
        ></Button>
        <Button
          icon={<EventM fill={palette.primary[700]} />}
          label={t('absencePlanner.taskbar.toolTip.events')}
          onClick={() => {
            setSelectedTaskUuid(null)
            setTaskBarState(taskBarStates.events)
          }}
        ></Button>
      </FilterContainer>
      <FilterContainer title={t('absencePlanner.taskbar.absencerequest.title')}>
        <PlanAbsenceButton
          onClick={() => {
            setTaskBarState(taskBarStates.newAbsenceRequest)
          }}
        />
      </FilterContainer>
    </Box>
  )
}

export default NativeCTA
