import { DocumentStatuses, DocumentTypes } from 'constants/documents'
import type { Locale } from 'date-fns'
import type { DocumentT } from 'types'
import { uiDateFormat, uiDateFormatNoYear } from './constants'
import { getFormattedFromISOString } from './date'
import { isNative } from './platform'

export const isMonthlySheet = (document: DocumentT): boolean => document.documentType === DocumentTypes.monthlySheet
export const isClosed = (document: DocumentT): boolean =>
  [DocumentStatuses.accepted, DocumentStatuses.claimAcceptedThisMonth].includes(document.status as DocumentStatuses)

export const formatDate = (date: string, locale?: Locale, format: string = uiDateFormat): string => {
  return getFormattedFromISOString(date, format, locale)
}

export const getCaption = (document: DocumentT, locale?: Locale): string => {
  if (document.data.startDate && document.data.endDate) {
    return `${formatDate(document.data.startDate, locale, uiDateFormatNoYear)} - ${formatDate(
      document.data.endDate,
      locale,
    )}`
  }
  return ''
}

export const displayDownloadButton = (document: DocumentT, hasBasicPackage = false) => {
  const monthlySheet = isMonthlySheet(document)

  if (isNative()) {
    return false
  }
  if (!monthlySheet) {
    return true
  }
  if (monthlySheet && !isClosed(document) && !hasBasicPackage) {
    return false
  }
  return true
}
