import { useContext } from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import { useHistory } from 'react-router-dom'
import FlashMessagesContext from 'services/FlashMessages/context'
import routes from 'services/RoutesProvider/routes'
import type { CmsContentBlocksT, CmsContentT, DocumentContentBlockT, ImageContentBlockT } from 'types'
import { ApolloError } from '@apollo/client'
import { BLOCK_TYPE } from '../constants'
import { getCmsContentQuery } from '../queries'
import { getBlockSrc } from './utils'

const updateBlocks = (blocks: CmsContentBlocksT[]): CmsContentBlocksT[] =>
  !blocks?.length
    ? []
    : blocks.map((block: CmsContentBlocksT) => ({
        data: {
          ...(block.data as object),
          ...(block.type === BLOCK_TYPE.document && {
            src: getBlockSrc({
              filename: (block.data as DocumentContentBlockT)?.filename,
              type: BLOCK_TYPE.document,
            }),
          }),
          ...(block.type === BLOCK_TYPE.image && {
            src: getBlockSrc({
              filename: (block.data as ImageContentBlockT)?.filename,
              type: BLOCK_TYPE.image,
            }),
          }),
        },
        language: block.language,
        order: block.order,
        type: block.type,
      }))

const useGetCmsContent = ({ contentUuid }: { contentUuid: string }) => {
  const { t } = useFavurTranslation()
  const { setFlashMessage } = useContext(FlashMessagesContext)
  const history = useHistory()
  const onError = (error: ApolloError) => {
    // code is coming from the backend as part of the error but is not being recognized as an apollo error prop
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((error.graphQLErrors[0] as any).code === 'error.be.cms.fetchingContent') {
      setFlashMessage(t('page.cms.content.error.be.fetchingContent'))
    } else setFlashMessage(error.message)

    history.push(`${routes.cmsContentIndex}`)
  }
  const { data: contentData, loading: loading } = useJamesApolloQuery<{ cmsContentGet: CmsContentT }>(
    getCmsContentQuery,
    {
      variables: { contentUuid: contentUuid },
      fetchPolicy: 'cache-and-network',
      onError: onError,
    },
  )

  return {
    content: { ...contentData?.cmsContentGet, blocks: updateBlocks(contentData?.cmsContentGet.blocks ?? []) },
    loading,
  }
}

export default useGetCmsContent
