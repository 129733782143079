import React, { useCallback, useMemo } from 'react'
import NotificationTemplate from 'components/Notification/Templates/Notification'
import { getBadgeGlossaryKey } from 'components/Notification/utils'
import { ROLE_VIEWS } from 'constants/roles'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useHistoryUtils from 'hooks/useHistoryUtils'
import routes from 'services/RoutesProvider/routes'
import { AbsenceRequestCommentForManagerNotificationT, NotificationT } from 'types'

interface IAbsenceRequestCommentManagerProps {
  notification: AbsenceRequestCommentForManagerNotificationT
  dismiss: (notification: NotificationT) => void
}

const AbsenceRequestCommentManager: React.FC<IAbsenceRequestCommentManagerProps> = (props) => {
  const { t } = useFavurTranslation()
  const { pushWithRole } = useHistoryUtils()

  const { notification, dismiss } = props

  const handleDetails = useCallback(() => {
    dismiss(notification)
    pushWithRole(`${routes.absenceRequestDetail}/${notification.data.taskUuid}`, ROLE_VIEWS.office)
  }, [dismiss, notification, pushWithRole])

  const body = useMemo(() => {
    const commentShort = notification.data.commentShort ?? t(getBadgeGlossaryKey(notification.data.badge))
    if (commentShort) {
      return t('notification.new.absence.manager.comment.body', {
        commentShort: commentShort,
      })
    }

    return ''
  }, [notification.data.badge, notification.data.commentShort, t])

  const templateProps = {
    ...props,
    title: t('notification.new.absence.manager.comment.title', {
      frontlinerNameShort: notification.data.frontlinerNameShort,
    }),
    body: body,
    dismissText: t('component.notification.button.dismiss'),
    details: handleDetails,
    detailsText: t('component.notification.button.viewDetails'),
  }
  return <NotificationTemplate {...templateProps} />
}

export default AbsenceRequestCommentManager
