import React, { useCallback, useMemo } from 'react'
import NotificationTemplate from 'components/Notification/Templates/Notification'
import { ROLE_VIEWS } from 'constants/roles'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useHistoryUtils from 'hooks/useHistoryUtils'
import routes from 'services/RoutesProvider/routes'
import { CmsNewContentNotificationT, NotificationT } from 'types'
import { contentTranslationPropsFromNotification } from './utils'

interface ICmsNewContentNotification {
  notification: CmsNewContentNotificationT
  dismiss: (notification: NotificationT) => void
}

const CmsNewContentNotification: React.FC<ICmsNewContentNotification> = (props) => {
  const { t, locale } = useFavurTranslation()
  const { pushWithRole } = useHistoryUtils()

  const { notification, dismiss } = props
  const handleDetails = useCallback(() => {
    dismiss(notification)
    pushWithRole(`${routes.cmsContentView}/${notification.data.contentUuid}`, ROLE_VIEWS.frontliner)
  }, [dismiss, notification, pushWithRole])

  const translations = useMemo(() => {
    switch (notification.data.type) {
      case 'page':
        return {
          title: 'notification.new.cms.new.page.inapp.title',
          body: 'notification.new.cms.new.page.inapp.body',
        }
      case 'event':
        return {
          title: 'notification.new.cms.new.event.inapp.title',
          body: 'notification.new.cms.new.event.inapp.body',
        }

      default:
        return {
          title: 'notification.new.cms.new.page.inapp.title',
          body: 'notification.new.cms.new.page.inapp.body',
        }
    }
  }, [notification.data.type])

  const translationProps = contentTranslationPropsFromNotification(notification, t, locale)

  const templateProps = useMemo(() => {
    return {
      ...props,
      title: t(translations.title, translationProps),
      body: t(translations.body, translationProps),
      details: handleDetails,
    }
  }, [handleDetails, props, t, translationProps, translations])

  return <NotificationTemplate {...templateProps} />
}

export default CmsNewContentNotification
