import React from 'react'
import { FF_BIO_PIN_ADDITIONAL_OPTIONS } from 'constants/featureFlags'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useFeatureFlag from 'hooks/useFeatureFlag'
import { BiometricM, CheckMarkM, DeleteTrashRemoveM } from 'icons'
import CardToggle from 'pages/UserSettings/components/CardToggle'
import UserSettingsSectionCTA from 'pages/UserSettings/components/SectionCTA'
import palette from 'utils/theme/palette'
import useBiometricOptionSettings from './useBiometricOptionSettings'

const BiometricOption: React.FC = () => {
  const { t } = useFavurTranslation()
  const bioPinAdditionalOptions = useFeatureFlag(FF_BIO_PIN_ADDITIONAL_OPTIONS) === true
  const {
    onChangeSwitch,
    onClickLogin,
    onClickDelete,
    engageBiometric,
    isBioAvailable,
    hasAvailableCredentials,
    isBioPinEnabled,
  } = useBiometricOptionSettings()

  return (
    <>
      {!isBioAvailable && (
        <UserSettingsSectionCTA
          title={t('profile.biometricLogin.notAvailable')}
          icon={<BiometricM fill={palette.primary.main} />}
          onClick={engageBiometric}
        />
      )}
      {isBioAvailable && (
        <CardToggle
          checked={isBioPinEnabled}
          name="bioPinEnabled"
          onChange={onChangeSwitch}
          title={t(`profile.biometricLogin.enable`)}
          icon={<BiometricM fill={palette.primary.main} />}
        />
      )}
      {bioPinAdditionalOptions && hasAvailableCredentials && (
        <UserSettingsSectionCTA
          title={t('page.userSettings.profile.settingsSection.biometricLogin.title')}
          icon={<DeleteTrashRemoveM fill={palette.primary.main} />}
          onClick={onClickDelete}
        />
      )}
      {bioPinAdditionalOptions && isBioAvailable && (
        <UserSettingsSectionCTA
          title={t('component.pinLogin.info')}
          icon={<CheckMarkM fill={palette.primary.main} />}
          onClick={onClickLogin}
        />
      )}
    </>
  )
}

export default BiometricOption
