import React from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import WhiteButton from 'components/WhiteButton'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { CloseThinM } from 'icons'
import { TutorialStepT } from '../types'
import { getTutorialImageUrl } from '../utils'
import { classes } from './styles'

export interface ITutorialPageProps {
  tutorialName: string
  stepProps: TutorialStepT
}

const StepPage: React.FC<ITutorialPageProps> = ({ tutorialName, stepProps }) => {
  const { key: stepName, close, goTo, goToTitle, mainText, titleText, extension } = stepProps
  const { locale } = useFavurTranslation()
  // we only do the background gradients on native because there's no design for them on web, and the positioning looks weird
  return (
    <Box sx={classes.flexContainer}>
      <Box sx={classes.header}>
        {close && (
          <IconButton onClick={close} sx={classes.buttonClose} size="large">
            <CloseThinM />
          </IconButton>
        )}
      </Box>
      <Box
        component="img"
        src={getTutorialImageUrl(tutorialName, stepName, locale?.code, extension)}
        alt={stepName}
        sx={classes.image}
      />
      <Box>
        <Typography variant="subtitle1" sx={classes.textTitle}>
          {titleText}
        </Typography>
        {mainText && (
          <Typography variant="body2" sx={classes.text}>
            {mainText}
          </Typography>
        )}
        {goTo && goToTitle && (
          <Box sx={classes.buttons}>
            <WhiteButton
              type="submit"
              size="small"
              variant="contained"
              color="primary"
              onClick={() => {
                goTo()
              }}
              sx={classes.buttonGoTo}
            >
              {goToTitle}
            </WhiteButton>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default StepPage
