import React, { useCallback, useMemo, useState } from 'react'
import { Box, Typography } from '@mui/material'
import useFilterContext from 'components/Filter/context'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { Trans } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { getShortName } from 'utils/person'
import HowToDialog from '../components/HowToDialog'
import { taskStates } from '../constants'
import classes from '../styles'
import { ShareUserDataTaskT, TaskCardDataT } from '../types'

const ShareUserDataBody: React.FC<TaskCardDataT<ShareUserDataTaskT>> = ({ office, task }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { storeState } = useFilterContext()
  const taskState = task.statusFe

  const [showDialog, setShowDialog] = useState(false)

  const titleTranslation = useMemo(() => {
    if (office) {
      if (taskState === taskStates.todo) {
        return 'tasks.new.sharedata.manager.open.headline'
      }
      if (taskState === taskStates.pending) {
        return 'tasks.new.sharedata.manager.pending.headline'
      }
      if (taskState === taskStates.closed) {
        return 'tasks.new.sharedata.manager.closed.headline'
      }
    }
    if (taskState === taskStates.todo) {
      return 'tasks.new.sharedata.frontliner.open.headline'
    }
    if (taskState === taskStates.pending) {
      return 'tasks.new.sharedata.frontliner.pending.headline'
    }
    return 'tasks.new.sharedata.frontliner.closed.headline'
  }, [office, taskState])

  const contentTranslations = useMemo(() => {
    const tenant = { key: 'tasks.new.sharedata.tenant', values: { tenantName: task.tenantName } }
    const explanation = { key: 'tasks.new.sharedata.explanation', values: {} }
    const commentFrontliner =
      task.taskData && task.taskData.userComment
        ? [
            {
              key: 'tasks.new.sharedata.comment_frontliner',
              values: { frontlinerComment: task.taskData.userComment },
            },
          ]
        : []
    const commentManager =
      task.taskData && task.taskData.reviewerComment
        ? [
            {
              key: 'tasks.new.sharedata.comment_manager',
              values: { managerComment: task.taskData.reviewerComment },
            },
          ]
        : []
    if (office) {
      if (taskState === taskStates.pending) return []
      if (taskState === taskStates.todo) return [...commentFrontliner, explanation]
      return [...commentFrontliner, ...commentManager]
    }
    if ([taskStates.todo, taskStates.pending].includes(taskState)) return [tenant]
    return [tenant, ...commentManager]
  }, [office, task, taskState])

  const detailsCallback = useCallback(() => {
    storeState()
    history.push(`${routes.userDataDetails}/${task.favurUuid}`)
  }, [history, storeState, task.favurUuid])

  return (
    <Box
      sx={classes.taskCardBody}
      data-testid={`${taskState}-${office ? 'office' : 'frontliner'}-share-user-data-body`}
    >
      <Typography sx={classes.taskCardBodyTitle} variant="subtitle1">
        {t(titleTranslation, { frontlinerNameShort: task.person ? getShortName(task.person) : '-' })}
      </Typography>
      <Box sx={[classes.taskCardContentWrapper]}>
        {contentTranslations.map((line) => (
          <Typography key={line.key} sx={classes.taskCardBodyContent} variant="body2">
            <Trans i18nKey={line.key} components={{ b: <b /> }} values={line.values} />
          </Typography>
        ))}
      </Box>
      <Box sx={classes.taskCardCTAContainer}>
        <Typography sx={classes.callToAction} variant="button" component="button" onClick={detailsCallback}>
          {t('tasks.absence_request.details')}
        </Typography>
      </Box>
      {showDialog && <HowToDialog onClose={() => setShowDialog(false)} />}
    </Box>
  )
}

export default ShareUserDataBody
