import React, { useCallback, useContext, useState } from 'react'
import { Button } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { EventsOfTask } from 'pages/Tasks/types'
import SendComment from '../SendComment'
import SuccessDrawer from '../SendComment/SuccessDrawer'
import { PlusContext } from './Plus'
import { classes } from './styles'

interface IAddCommentProps {
  taskUuid: string
  event: EventsOfTask.shareUserDataSendCommentToManager | EventsOfTask.shareUserDataSendCommentToFrontline
  onAction?: () => void
  showSuccessDrawer?: boolean
}

const AddComment: React.FC<IAddCommentProps> = ({ event, taskUuid, onAction, showSuccessDrawer = false }) => {
  const { t } = useFavurTranslation()

  const { setIsOpenDrawer: setPlusDrawerOpen } = useContext(PlusContext)

  const [openDrawer, setOpenDrawer] = useState(false)
  const [openSuccessDrawer, setOpenSuccessDrawer] = useState(false)

  const onCompleted = useCallback(() => {
    setOpenSuccessDrawer(false)
    setPlusDrawerOpen && setPlusDrawerOpen(false)
    setOpenDrawer(false)
    onAction && onAction()
  }, [onAction, setPlusDrawerOpen])

  return (
    <>
      <Button
        onClick={() => setOpenDrawer(true)}
        data-testid="share-user-data-detail_add_comment"
        variant="contained"
        sx={classes.regularButton}
        disableRipple
        disableFocusRipple
        disableTouchRipple
      >
        {t('shareUserData.buttons.addComment')}
      </Button>
      <SendComment
        isOpen={openDrawer}
        onCancel={() => setOpenDrawer(false)}
        eventOnSuccess={event}
        taskUuid={taskUuid}
        eventCompleted={() => (showSuccessDrawer ? setOpenSuccessDrawer(true) : onCompleted())}
      />
      <SuccessDrawer open={openSuccessDrawer} onClose={onCompleted} />
    </>
  )
}

export default AddComment
