import { useCallback, useState } from 'react'
import { userNameQuery } from 'shared/queries'
import type { GraphQLResponse, SettingsT, User } from 'types'
import { useJamesApolloQuery } from './useJamesApolloQuery'

interface IUseUserName {
  useCache?: boolean
}

const useUsername = ({ useCache }: IUseUserName = { useCache: false }) => {
  const [user, setUser] = useState<User>()

  const { loading } = useJamesApolloQuery<GraphQLResponse>(userNameQuery, {
    fetchPolicy: useCache ? 'cache-and-network' : 'no-cache',
    onCompleted(data) {
      const dataUser = data.me?.user
      if (dataUser) {
        setUser(dataUser)
      }
    },
  })

  const getUserData = useCallback(
    () => ({
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      fullName: user ? `${user?.firstName} ${user?.lastName}` : '',
      userId: user?.userId,
      avatarUrl: user?.avatarUrl,
      phoneNumber: user?.phoneNumber ?? '',
      gender: user?.gender ?? '',
      birthDate: user?.birthDate ?? '',
      settings: user?.settings ?? ({} as SettingsT),
    }),
    [user],
  )

  return { ...getUserData(), loading }
}

export default useUsername
