import React from 'react'
import { Box } from '@mui/material'
import AbsenceRequestData from 'components/ShiftsList/components/ShiftCard/AbsenceRequestData'
import ShiftData from 'components/ShiftsList/components/ShiftCard/ShiftData'
import { SelectedShiftDataT } from 'pages/AbsencePlanner/types'
import { AbsenceRequestTaskT, ShiftUpdateTaskT, TaskT } from 'pages/Tasks/types'
import { ShiftsByTenantCCT, TenantT } from 'types'
import { classes } from './styles'

interface IShiftContentProps {
  shifts?: ShiftsByTenantCCT[]
  selectedShift?: SelectedShiftDataT | null
  date: string
  onClickLink: (task: TaskT<AbsenceRequestTaskT>) => void
  absenceRequests: TaskT<AbsenceRequestTaskT>[]
  userIsAbsenceManager: boolean
  shiftUpdates?: TaskT<ShiftUpdateTaskT>[]
}

const ShiftContent: React.FC<IShiftContentProps> = ({
  shifts,
  selectedShift,
  date,
  onClickLink,
  absenceRequests,
  userIsAbsenceManager,
  shiftUpdates = [],
}) => {
  return (
    <>
      <Box sx={classes.shiftContainer}>
        <Box sx={classes.shiftData}>
          <ShiftData
            shiftData={shifts ?? []}
            tenant={selectedShift?.tenant as TenantT}
            date={selectedShift?.date}
            avatarData={selectedShift?.avatarData}
            hideEffectiveTimes
            hideHistoricalShifts
            hideAbsenceType={!userIsAbsenceManager}
            hideAddAvatarBadge
            showAvatarUserName
            shiftUpdates={shiftUpdates}
          />
          {userIsAbsenceManager && (
            <AbsenceRequestData absenceRequests={absenceRequests} date={date} onClickLink={onClickLink} />
          )}
        </Box>
      </Box>
    </>
  )
}

export default ShiftContent
