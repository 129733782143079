import { useMemo } from 'react'
import useListCmsPaginatedContents from 'hooks/useListCmsPaginatedContents'
import { CONTENT_TYPES } from 'types/cms'
import { getEventsInDateRange } from 'utils/cms'
import { getDatetimeRangeWithTimezone } from 'utils/date'

interface IEventsDateRangeProps {
  startDate: string
  endDate: string
}

export const useEventsDateRange = ({ startDate, endDate }: IEventsDateRangeProps) => {
  const eventsDateRange = useMemo(() => getDatetimeRangeWithTimezone({ start: startDate, end: endDate }), [
    endDate,
    startDate,
  ])

  const { list: events } = useListCmsPaginatedContents({
    ...eventsDateRange,
    contentType: CONTENT_TYPES.event,
    pageSize: 0,
  })

  const eventsByDate = useMemo(() => getEventsInDateRange(events, { start: startDate, end: endDate }), [
    endDate,
    events,
    startDate,
  ])

  return { eventsByDate: eventsByDate }
}
