import React from 'react'
import ActionCardButtons from 'components/Buttons/ActionCardButtons'
import { taskStates } from 'pages/Tasks/constants'
import { EventsOfTask, ShareUserDataTaskT, TaskT } from 'pages/Tasks/types'
import AddComment from './AddComment'
import DeleteWorkflow from './DeleteWorkflow'
import FillForm from './FillForm'
import Plus from './Plus'
import SendReminder from './SendReminder'

const getButtonsFromStateFrontline = (task: TaskT<ShareUserDataTaskT>, onAction?: () => void) => {
  const { favurUuid } = task
  if (task.statusFe === taskStates.todo) {
    return (
      <ActionCardButtons>
        <FillForm />
        <Plus>
          <AddComment taskUuid={favurUuid} event={EventsOfTask.shareUserDataSendCommentToManager} onAction={onAction} />
        </Plus>
      </ActionCardButtons>
    )
  }

  if (task.statusFe === taskStates.pending) {
    return (
      <ActionCardButtons>
        <Plus>
          <AddComment taskUuid={favurUuid} event={EventsOfTask.shareUserDataSendCommentToManager} onAction={onAction} />
        </Plus>
      </ActionCardButtons>
    )
  }

  return <></>
}

const getButtonsFromStateOffice = (task: TaskT<ShareUserDataTaskT>, onAction?: () => void) => {
  const { favurUuid } = task
  if (task.statusFe === taskStates.todo) {
    return (
      <ActionCardButtons>
        <Plus>
          <AddComment
            taskUuid={favurUuid}
            event={EventsOfTask.shareUserDataSendCommentToFrontline}
            onAction={onAction}
          />
        </Plus>
      </ActionCardButtons>
    )
  }
  if (task.statusFe === taskStates.pending) {
    return (
      <ActionCardButtons>
        <Plus>
          <AddComment
            taskUuid={favurUuid}
            event={EventsOfTask.shareUserDataSendCommentToFrontline}
            onAction={onAction}
          />
          <SendReminder taskUuid={favurUuid} onAction={onAction} isOffice />
          <DeleteWorkflow task={task} />
        </Plus>
      </ActionCardButtons>
    )
  }
  return <></>
}

interface IActionButtonsProps {
  task: TaskT<ShareUserDataTaskT>
  isOffice: boolean
  onAction?: () => void
}

const getButtonsFromTaskAndView = ({ task, isOffice, onAction }: IActionButtonsProps) => {
  if (task.statusFe === taskStates.closed) {
    return <></>
  }

  if (isOffice) {
    return getButtonsFromStateOffice(task, onAction)
  }

  return getButtonsFromStateFrontline(task, onAction)
}

const ActionButtons: React.FC<IActionButtonsProps> = ({ task, isOffice, onAction }) => {
  if (task.timeline && task.timeline.length > 0) {
    return <>{getButtonsFromTaskAndView({ task, isOffice, onAction })}</>
  }
  return <></>
}

export default ActionButtons
