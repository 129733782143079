import React, { useCallback, useEffect, useState } from 'react'
import Tutorial from 'components/Tutorial'
import IntroPage from 'components/Tutorial/IntroPage'
import StepPage from 'components/Tutorial/StepPage'
import { TutorialStepT } from 'components/Tutorial/types'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useStateBackLink from 'hooks/useStateBackLink'
import useTutorials from 'hooks/useTutorials'
import routes from 'services/RoutesProvider/routes'

const AbsenceRequestManager: React.FC = () => {
  const tutorialName = 'absenceRequestManager'
  const { t } = useFavurTranslation()
  const [step, setStep] = useState(0)
  const { markAsSeen, isReady, beenShownBefore } = useTutorials()
  const { pushBackLinkOrDefault } = useStateBackLink()

  useEffect(() => {
    if (!isReady || beenShownBefore(tutorialName)) return
    markAsSeen(tutorialName)
  }, [beenShownBefore, isReady, markAsSeen])

  const goBack = useCallback(() => {
    pushBackLinkOrDefault(routes.tasks)
  }, [pushBackLinkOrDefault])

  const stepPages: TutorialStepT[] = [
    {
      key: 'step1',
      titleText: t(`tutorial.absenceRequestManager.step1.titleText`),
    },
    {
      key: 'step2',
      titleText: t(`tutorial.absenceRequestManager.step2.titleText`),
      mainText: t(`tutorial.absenceRequestManager.step2.text`),
    },
    {
      key: 'step3',
      titleText: t(`tutorial.absenceRequestManager.step3.titleText`),
      mainText: t(`tutorial.absenceRequestManager.step3.text`),
    },
    {
      key: 'step4',
      close: () => goBack(),
      titleText: t(`tutorial.absenceRequestManager.step4.titleText`),
      mainText: t(`tutorial.absenceRequestManager.step4.text`),
    },
  ]

  const stepPagesComponents = stepPages.map((stepPage) => (
    <StepPage key={stepPage.key} tutorialName={tutorialName} stepProps={stepPage} />
  ))

  const introPage = <IntroPage tutorialName={tutorialName} stepName="intro" close={goBack} />

  return (
    <Tutorial
      introPage={introPage}
      stepPages={stepPagesComponents}
      step={step}
      setStep={setStep}
      onSwipeEnd={() => goBack()}
    />
  )
}

export default AbsenceRequestManager
